<template>
  <div class="portfolio-sharing-wrap pt-embedded-in-ios">
    <div class="sticky-header has-background-white">
      <MenuAndLogo :showReferral="true" :hasNavBack="true"></MenuAndLogo>
    </div>
    <div class="page-header has-text-centered has-background-white">
      <div class="property-nick-name has-text-weight-semibold">{{propertyNickname}}</div>
      <div class="property-address">{{propertyName}}</div>
    </div>
    <div class="share-certificate">
      <div class="page-title has-text-weight-semibold">Share your certificate</div>
      <div class="page-subtitle mb-5">Go ahead, show off your ownership certificate and share your personal referral link with others to start earning rewards—we would too!
        <a class="referral-blog" @click="openLink(referralBlogLink)">Learn more.</a>
      </div>
      <InvestmentShareCertificate
        v-if="certificateDetails != null"
        :certificate-title="certificateDetails.certificateTitle"
        :member-full-name="certificateDetails.investorName"
        :security-type="certificateDetails.securityType"
        :security-count="certificateDetails.securityCount"
        :date="certificateDetails.date"
        :date-label="certificateDetails.dateLabel"
      />
      <div class="has-text-centered" @click="shareCertificate">
        <b-button type="is-blue button-share-certificate" class="has-text-weight-bold is-medium-height">Share my certificate</b-button>
      </div>
    </div>
    <div class="share-investments">
      <div class="page-title has-text-weight-semibold">Flex your investment</div>
      <div class="page-subtitle">Share your property investment link with others—it deserves to be shown off 💪</div>
      <div :class="`dash-box ${linkCopied ? 'link-copied' : ''}`" @click="copyLink">
        <div class="referral-link has-text-blue has-text-weight-semibold">{{publicPortfolioLink}}</div>
        <div class="text-of-shared-link has-text-centered">{{textOfSharedLink}}</div>
      </div>
      <div class="has-text-centered" @click="sharePortfolio">
        <b-button type="is-blue button-share-portfolio" class="has-text-weight-bold is-medium-height">Share my investment</b-button>
      </div>
      <div class="has-text-centered check-my-portfolio-link">Want to view what your friends see?
        <a class="has-text-primary" @click="openLink(publicPortfolioLink)">Check it out</a>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@lib/axios/middleware'
import { getPublicPortfolioToken, getPortfolioDetails } from '@api/portfolio'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import InvestmentShareCertificate from './InvestmentShareCertificate'
import { mapState } from 'vuex'
import { embeddedInIos } from '@utils/common-methods/common'
import webShareTagsString from '@utils/data/web_share_tags.js'

export default {
  data() {
    return {
      addyHandle: '',
      publicPortfolioToken: '',
      propertyNickname: '',
      propertyName: '',
      linkCopied: false,
      timer: undefined,
      copyTextChanged: false,
      certificateDetails: null,
    }
  },
  components: {
    MenuAndLogo,
    InvestmentShareCertificate,
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    referralBlogLink() {
      return 'https://www.addyinvest.com/2021/06/29/referral-program/'
    },
    publicPortfolioLink() {
      return `${location.origin}/portfolio/publicPortfolio?jwt=${encodeURIComponent(this.publicPortfolioToken)}&addyHandle=${encodeURIComponent(this.addyHandle)}`
    },
    textOfSharedLink() {
      return this.copyTextChanged ? 'Copied!' : 'Tap to copy!'
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const buyId = decodeURIComponent(this.$route.query.transactionId)
      axios.all([
        getPublicPortfolioToken({
          buy_id: buyId
        }),
        getPortfolioDetails({
          buy_id: buyId
        }),
      ]).then((data) => {
        if (data[0].success) this.publicPortfolioToken = data[0].data.publicToken
        if (data[1].success) {
          const { membersAddyHandle, propertyName, propertyNickname, certificateData } = data[1].data
          this.addyHandle = membersAddyHandle
          this.propertyName = propertyName
          this.propertyNickname = propertyNickname
          this.certificateDetails = certificateData
        }
      })
    },
    doWebShare({ title, text, url }) {
      navigator.share({
        title,
        url,
        text: text + webShareTagsString,
      })
    },
    shareCertificate() {
      this.mixpanel.track('7rdyoyjy')
      this.doWebShare({
        title: 'Hey, check out my share certificate',
        text: 'Hey, check out my share certificate — I own a part of a property! I highly recommend becoming an addy member. addy provides awesome opportunities to invest in real estate for an amount that fits your budget — opportunities that are typically unreachable. Best of all, it’s easy to use and signing up takes less than 2 mins! Join using my personal invite link below:',
        url: `${location.origin}/portfolio/publicPortfolio?jwt=${encodeURIComponent(this.publicPortfolioToken)}&addyHandle=${encodeURIComponent(this.addyHandle)}&certificate=true`,
      })
    },
    sharePortfolio() {
      this.mixpanel.track('nqeomgce')
      this.doWebShare({
        title: 'Hey, check out my property investment',
        text: `Hey, check out my property investment — I own a part of a property! I highly recommend becoming an addy member so you can invest in a property too. Let’s #InvestTogether 🙌`,
        url: this.publicPortfolioLink,
      })
    },
    copyLink() {
      if (!this.copyTextChanged) this.copyTextChanged = true
      let delay = 0
      if (this.linkCopied) {
        this.linkCopied = false
        delay = 200
      }
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$copyText(this.publicPortfolioLink).then(() => {
          this.linkCopied = true
        }, (e) => {
          this.$buefy.toast.open({
            message: e,
            duration: 3000,
            type: 'is-danger',
          })
        })
      }, delay)
    },
    openLink(link) {
      if (embeddedInIos()) return window.webkit.messageHandlers.openURL.postMessage(link)
      window.open(link)
    },
  },
}
</script>
<style lang="scss" scoped>
.portfolio-sharing-wrap {
  background-color: #FDFDFD;
  padding-top: 90px;
  .sticky-header {
    width: 100%;
    height: 68px;
    box-shadow: 0px 0px 12px rgba(45, 41, 38, 0.05);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
  }
  .page-header {
    width: 100vw;
    padding: 0 6px 25px;
    .property-nick-name {
      font-size: 24px;
      margin-bottom: 8px;
    }
    .property-address {
      font-size: 16px;
    }
  }
  .page-title {
    font-size: 20px;
    line-height: 29px;
    padding: 0 25px;
  }
  .page-subtitle {
    font-size: 16px;
    line-height: 19px;
    padding: 0 25px;
  }
  .share-certificate {
    .referral-blog {
      color: #8f86ab;
    }
    .share-certificate-image {
      margin-top: 28px;
      border-radius: 6px;
      width: 356px;
    }
    .button-share-certificate {
      width: 270px;
      border-radius: 12px;
    }
  }
  .share-investments {
    margin-top: 48px;
    padding-bottom: 80px;
    .dash-box {
      width: 269px;
      height: 62px;
      background: url('../../../assets/images/referral/dash-outline.png') center/contain no-repeat;
      border-radius: 12px;
      margin: 22px auto 14px;
      padding: 10px 20px 0;
      &.link-copied {
        background-color: #F0EEF8;
      }
      .referral-link {
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .text-of-shared-link {
        margin-top: 8px;
        color: #9185AE;
        font-size: 14px;
      }
    }
    .button-share-portfolio {
      width: 270px;
      border-radius: 12px;
    }
    .check-my-portfolio-link {
      font-size: 14px;
      color: #9185AE;
      margin-top: 16px;
    }
  }
}
</style>
